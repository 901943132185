.scorer-card {
    background-color: #e8e2fe;
    border-radius: 10px;
    padding: 15px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    text-align: center;
    color: #333; /* Default text color */
  }

  .scorer-card-1 {
    background-color: #CDC3FF;
    border-radius: 10px;
    padding: 15px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    text-align: center;
    color: #333; /* Default text color */
  }

  .scorer-card-2 {
    background-color: #AAC9FF;
    border-radius: 10px;
    padding: 15px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    text-align: center;
    color: #333; /* Default text color */
  }

  .scorer-card-3 {
    background-color: #92E3B8;
    border-radius: 10px;
    padding: 15px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    text-align: center;
    color: #333; /* Default text color */
  }
  
  .scorer-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .scorer-header h3 {
    margin: 0;
    font-size: 24px;
  }
  
  .rank {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
  }
  
  .scorer-body {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .scorer-avatar {
    background-color: #DAE6FE;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    color: #000000;
    margin-right: 10px;
  }

  .scorer-avatar-1 {
    background-color: #c1d1ff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    color: #1254e0;
    margin-right: 10px;
  }

  .scorer-avatar-2 {
    background-color: #c1d1ff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    color: #1254e0;
    margin-right: 10px;
  }

  .scorer-avatar-3 {
    background-color: #c1d1ff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    color: #1254e0;
    margin-right: 10px;
  }
  
  .scorer-details p {
    margin: 0;
    font-size: 14px;
    line-height: 1.2;
  }