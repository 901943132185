.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url('../assets/BG_login.png') no-repeat center center fixed;
  background-size: cover;
}

.login-logo {
  margin-bottom: 2rem; /* Space between the logo and login box */
}

.login-box {
  background: #ffffff;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 400px;
  max-width: 100%;
}

.login-box h2 {
  text-align: left;
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.form-control {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
}

.btn-primary {
  width: 100%;
  padding: 0.75rem;
  background-color: #1254E0;
  border: none;
  border-radius: 0.25rem;
  color: #fff;
  font-size: 1rem;
}

.btn-primary:disabled {
  background-color: #5a6268;
}

.forgot-password {
  text-align: center;
  margin-top: 1rem;
}

.forgot-password button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}
