.dual-listbox {
  display: flex;
  align-items: center;
}

.list-container {
  width: 50%;
  /* background-color: #F3F4F8; */
  font-size: large;
}

.lesson-select-header{
    background-color: #DAE6FE;
    color: #1254E0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 5px 0 5px 5px;

}

.lesson-select {
  min-height: 300px;
  min-width: 500px;
  width: 100%;
  height: auto;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 10px;
  border: 1px solid #e2e8f0;
  background-color: #F3F4F8;
  scrollbar-width: none;
}

.lesson-controls {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
}

.lesson-button {
  margin: 10px;
  padding: 10px;
  border: none;
  background-color: #1254E0;
  color: white;
  border-radius: 8px;
  cursor: pointer;
}

.lesson-button:hover {
  background-color: #2b6cb0;
}
