.image-dropzone-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75px;
  border-radius: 4px;
  border: 2px dashed rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.image-dropzone-container.dragging {
  background-color: rgba(0, 0, 0, 0.1);
}

.image-dropzone-message {
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
}

.image-preview-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.image-preview-item {
  position: relative;
  margin: 10px;
}

.image-preview {
  max-width: 100px;
  max-height: 100px;
  border-radius: 10px;
}

.remove-image-button {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #ff0000;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}
