.lesson-group-btn {
    background-color: #F3F4F8; 
    border: none;
    border-radius: 12px; 
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    margin: 5px;
    font-size: 16px;
    color: #000000; 
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .lesson-group-btn:hover {
    background-color: #DAE6FE; 
  }
  
  .lesson-group-btn.active {
    background-color: #DAE6FE; 
    color: #1254E0;
    font-weight: bold;
  }