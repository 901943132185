.loading-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8); /* Optional: Adds a translucent background */
    z-index: 9999; /* Ensure it appears above other elements */
  }
  
  .loading-spinner {
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
  }
  