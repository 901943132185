/* ChartComponent.css */
.chart {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px; /* Add margin between charts */
  }
  
  .chart h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  
  .chart-placeholder {
    height: 200px; /* Set the height for the chart placeholder */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    border-radius: 10px;
  }
  
  .chart-details {
    margin-top: 15px;
    font-size: 14px;
  }
  
  .chart-details p {
    margin: 0;
    line-height: 1.5;
  }
  
  .text-primary {
    color: #007bff;
    font-weight: bold;
  }
  