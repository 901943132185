.dropzone-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75px;
    border-radius: 4px;
    border: 2px dashed rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s ease;
    cursor: pointer;
  }
  
  .dropzone-container.dragging {
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .dropzone-message {
    font-size: 16px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
  }
  
  .select-file-button {
    position: relative;
    top: 0;
    left: 0;
    width: 20%;
    height: 50%;
    margin-right: 5%;
    opacity: 1;
    cursor: pointer;
  }
  