.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* index.css or App.css */
body {
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-weight: 400;
  overflow-x: hidden;  /* Hide horizontal overflow */
  overflow-y: auto;    /* Automatically add vertical scroll if needed */
}

.wrapper {
  display: flex;
  flex-direction: column;  /* Flex items are stacked vertically by default */
  min-height: 100vh;
  overflow-x: auto;
  background-color: #f8f9fa;
}

@media (max-width: 768px) {
  .wrapper {
    flex-direction: row; /* Changes direction to horizontal under 768px */
  }
}

.content-wrapper {
  flex: 1;
  /* padding: 20px; */
  margin-left: 230px;
  margin-top: 60px;
  transition: margin-left 0.3s ease;
}

.content-wrapper.collapsed {
  margin-left: 60px;
}

@media (max-width: 768px) {
  .table {
    width: max-content;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .sidebar {
    position: absolute;
    width: 230px;
    z-index: 1000;
    height: 100%;
    /* left: -230px; */
    transition: left 0.3s ease;
  }

  .sidebar.collapsed {
    left: 0;
  }

  .content-wrapper {
    margin-left: 0;
    /* padding: 10px; */
  }

  .header-title {
    font-size: 1.5rem; /* Adjust the title size */
    text-align: center;
  }
}

/* Additional styling for mobile devices */
@media (max-width: 480px) {
  .header-title {
    font-size: 1.2rem; /* Further adjustment for smaller screens */
  }

  .footer {
    text-align: center;
    padding: 10px;
  }

  /* .content-wrapper {
    padding: 5px;
  } */
}


.learner-content-wrapper {
  flex: 1;
  /* margin-left: 230px;  */
  margin-top: 60px; 
  background-color: #f8f9fa;
  transition: margin-left 0.3s;
}

.box-white {
  background-color: white;
  border: none;
  padding: 15px;
  margin: 6px;
  box-shadow: 2px 2px 2px #dbdbdb;
  align-self: center !important;
  border-radius: 10px;
  border: 1px solid #DDDEDF;
}

.ic-actions {
  width: 20px;
  height: auto;
  object-fit: contain;
}


.icon-btn{ 
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer
}

.icon-light {
  font-weight: 300; /* This makes the icon appear lighter */
}

.select-page{
  width: auto !important;
  border: 1px solid #D1D3D4 !important;
}


.custom-btn {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s ease;
}


.btn-create {
  background-color: #0069FF;
  color: #FFFFFF;
  border: none;
  cursor: pointer;
}

.btn-create:hover {
  background-color: #0056d6;
}

/* Disabled State */
.btn-create:disabled {
  background-color: #b0c4de; /* A lighter color to indicate the disabled state */
  color: #ffffff;
  cursor: not-allowed;
  opacity: 0.65;
}

.btn-cancel {
  background-color: #104C5E;
  color: #FFFFFF;
  border: none;
}

.btn-cancel:hover {
  background-color: #0f4657;
}

.btn-chp {
  background-color: #16A2B8;
  color: #FFFFFF;
  border: none;
  border-radius: 5px;
  width: auto;
  height: 40px;
  padding: 0 10px;
  cursor: pointer;
  color: white;
}

.btn-chp:hover {
  background-color: #15b4cc;
}


.btn-secondary-custom {
  background-color: #F3F4F8;
  color: #000000;
  border: none;
}

.btn-secondary-custom:hover {
  background-color: #E2E3E7;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}


/* Search Bar */
.search-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.search-input {
  border: 1px solid #ddd;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 16px;
  outline: none;
  width: 200px;
  transition: width 0.4s ease-in-out;
}

.search-input:focus {
  width: 300px;
}

.search-button {
  border: 1px solid #ddd;
  background-color: white;
  border-radius: 50%;
  padding: 10px;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.search-button:hover {
  background-color: #f0f0f0;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.text-sm {
  font-size: 0.875rem !important;
}

.fw-bold {
  font-weight: bold !important;
}

.select-page {
  border-radius: 5px;
}
/*End Search Bar */

/* Custom Checkbox */
.custom-checkbox .switch {
  position: relative;
  display: inline-block;
  width: 100px; /* Adjusted to fit the full text */
  height: 34px;
}

.custom-checkbox .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom-checkbox .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.custom-checkbox .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.custom-checkbox input:checked + .slider {
  background-color: #2196F3;
}

.custom-checkbox input:checked + .slider:before {
  transform: translateX(60px); /* Adjust to match the new width */
}

.status-text {
  display: inline-block;
  color: white;
  font-size: 12px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.custom-checkbox input:checked + .slider .status-text {
  left: 20px; /* "ใช้งาน" aligned to start */
  text-align: left;
}

.custom-checkbox input:not(:checked) + .slider .status-text {
  right: 20px; /* "ไม่ใช้งาน" aligned to end */
  text-align: right;
}

.react-datepicker-wrapper{
  display: block !important;
}

.btn-upload {
  background-color: #b9cfe0 !important;
  border: 1px solid  #C6D6E2 !important;
  border-radius: 5px;
  width: 120px !important;
  height: 32px !important;
  padding: 0 10px;
  cursor: pointer;
  color: #000000;
  margin-left: 10px;
  font-weight: 500;
}
.btn-upload:hover {
  background-color: #C6D6E2 !important;
}


/* modal */
.modal-content {
  background-color: #fff !important;
  border: 1px solid rgb(255 255 255 / 18%) !important;
}

.area100 {
  width: 100%;
}

.colorGray01 {
  color: #3B4752;
}

.colorGray02 {
  color: #939598;
}

.colorBlue01 {
  color: #12349F;
}

.colorBlue02 {
  color: #657BB1;
}

.colorBlack01 {
  color: #000000;
}

.colorWhite {
  color: white;
}

.colorRed {
  color: #eb0019;
}

.colorGreen01 {
  color: #00A79D;
}

.colorGreen02 {
  color: #90CD8D;
}

.colorYellow01 {
  color: #FDDC00;
}

.colorViolet01 {
  color: #C298FF;
}

.colorViolet02 {
  color: #5C1B6C;
}

.colorPink01 {
  color: #FF90AB;
}

.ck.ck-editor__editable_inline>:last-child {
  margin-bottom: 2rem !important;
}

.ck.ck-editor__editable_inline>:first-child {
  margin-top: 2rem !important;
}

.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 10px 0 0;
}

.radio-item input[type='radio'] {
  display: none;
}

.radio-item label {
  color: #000000;
  font-weight: normal;
}

.radio-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2.5px solid #55C091;
  background-color: transparent;
}

.radio-item input[type=radio]:checked+label:after {
  border-radius: 11px;
  width: 9px;
  height: 9px;
  position: absolute;
  top: 11px;
  left: 5px;
  content: " ";
  display: block;
  background: #55C091;
}

.form-check {
  padding-left: 0 !important;
}


.bd-line01 {
  border-bottom: 1px solid #dbdbdb;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 15px;
}

.bd-line02 {
  border-bottom: 1px solid #dbdbdb;
  padding: 10px;
}

.bdl-red {
  border-left: 5px solid #EC1B30;
}

.line-height01 {
  line-height: 1.8;
}

.line-height02 {
  line-height: 1.5;
}

.line-height03 {
  line-height: 1;
}

.line-height04 {
  line-height: 2;
}

.fontReg {
  font-family: 'db_heaventregular' !important;

}

.font-table-style-custom {
  font-family: 'db_heaventregular' !important;
  font-size: 12px !important;
  white-space: nowrap !important;

}

.fontLight {
  font-family: 'db_heaventlight' !important;
}

.font-size01 {
  font-size: 1rem !important;
  font-size: clamp(0.9rem, 1.5vw, 1rem) !important;
}

.font-size02 {
  font-size: 1.1rem !important;
  font-size: clamp(0.9rem, 1.5vw, 1.1rem) !important;
}

.font-size03 {
  font-size: 0.9rem !important;
  font-size: clamp(0.7rem, 1.5vw, 0.9rem) !important;
}

.font-size04 {
  font-size: 2rem !important;
  font-size: clamp(1.1rem, 2.5vw, 2rem) !important;
}

.font-size05 {
  font-size: 1.5rem !important;
  font-size: clamp(0.7rem, 2.5vw, 1.5rem) !important;
}

.im-tiltle {
  width: 50px;
  height: auto;
  object-fit: contain;
  margin-right: 15px;
}

.ic-files {
  width: 20px;
  height: auto;
  object-fit: contain;
  margin-right: 10px;
}

.ic-add {
  width: 15px;
  height: auto;
  object-fit: contain;
  margin-right: 5px;
}

.ic-search {
  width: 15px;
  height: auto;
  object-fit: contain;
  margin-right: 5px;
}

.ic-Recommend {
  width: 22px;
  height: auto;
  object-fit: contain;
  margin-right: 5px;
}


.btn-expFile {
  background-color: #fff;
  border: 1px solid #D1D3D4;
  border-radius: 5px;
  width: 30%;
  height: 40px;
  padding: 0 10px;
  cursor: pointer;
}

.btn-expFile:hover {
  background-color: #e1e1e1;
}

.btn-secondary {
  color: rgb(0, 0, 0);
  background-color: #D1D3D4;
  border: 1px solid #D1D3D4;
  box-shadow: none;
}

.btn-secondary:hover {
  background-color: #bcbcbc;
  border: 1px solid #D1D3D4;
  color: rgb(0, 0, 0);
  outline: none;
}

.card-white {
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: white;
  border-radius: 15px;
  border: none;
}

.im-slip {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.box-process {
  border-radius: 50px;
  background-color: #FA9349;
  padding: 10px 20px;
  color: #fff;
}

.box-paid {
  border-radius: 50px;
  background-color: #87BD66;
  padding: 10px 20px;
  color: #fff;
}

.box-fail {
  border-radius: 50px;
  background-color: #D95350;
  padding: 10px 20px;
  color: #fff;
}

.border-left01 {
  border-left: 5px solid #06BBFF;
  margin-right: 5px;
}

.border-right01 {
  border-right: 1px solid #D1D3D4;
}

.border-right02 {
  border-right: 1px solid #D1D3D4;
}

:focus-visible {
  outline: -webkit-focus-ring-color auto 0;
}

.card-body {
  padding: 1rem !important;
}



.im-sports-type {
  width: 40%;
  height: auto;
  object-fit: contain;
}

.im-sports-type-dt {
  width: 30%;
  height: auto;
  object-fit: contain;
}

.ic-add {
  width: 12px;
  height: auto;
  object-fit: contain;
}

.ic-actions {
  width: 15px;
  height: auto;
  object-fit: contain;
}

.ic-edit {
  width: 15px;
  height: auto;
  object-fit: contain;
}

.im-logo {
  width: 300px;
  height: auto;
  object-fit: contain;
  z-index: 2;
}

.h-100vh {
  height: calc(100vw * (9/16));
  max-height: calc(30em * (9/16));
}

.btn-cancel {
  background-color: #104C5E;
  border: 1px solid #104C5E;
  border-radius: 5px;
  width: auto;
  height: 40px;
  padding: 0 10px;
  cursor: pointer;
  color: white;
}

.btn-cancel:hover {
  background-color: #0d4151;
}

.btn-save {
  background-color: #1254E0;
  border: 1px solid #1254E0;
  border-radius: 5px;
  width: auto;
  height: 40px;
  padding: 0 10px;
  cursor: pointer;
  color: white;
  margin-left: 10px;
}

.btn-save:hover {
  background-color: #2863e3;
}



.ic-actions {
  width: 20px;
  height: auto;
  object-fit: contain;
}

.drpdw-style {
  position: relative;
  width: 100%;
  height: auto;
  padding: 5px 10px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #D1D3D4;
}

.headermenu {
  cursor: pointer;
}

.inp-area {
  display: block;
  width: 100%;
  height: 100%;
  padding: 15px 6px;
  font-family: 'db_heaventregular';
  line-height: 1.5;
  font-size: 0.9rem;
  font-size: clamp(0.7rem, 1.5vw, 0.9rem);
  font-weight: 400;
  color: #000000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.im-profile {
  width: 145px;
  height: auto;
  object-fit: contain;
}

.brand-link {
  padding: 0 !important;
}

/* .brand-image {
  width: 100% !important;
  max-height: 80px !important;
  margin-right: 0 !important;
  margin-top: 0 !important;
} */

.bdl-yellow01 {
  border-left: 5px solid #FDDC00;
}

/* .main-sidebar {
  overflow-y: visible !important;
} */

.pdb-page {
  padding-bottom: 80px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-top: 20px !important;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0 !important;
  content: "";
  border-top: none !important;
  border-right: none !important;
  border-bottom: none !important;
  border-left: none !important;
}


@media only screen and (max-width : 1440px) {

  table.dataTable thead>tr>th.sorting,
  table.dataTable thead>tr>th.sorting_asc,
  table.dataTable thead>tr>th.sorting_desc,
  table.dataTable thead>tr>th.sorting_asc_disabled,
  table.dataTable thead>tr>th.sorting_desc_disabled,
  table.dataTable thead>tr>td.sorting,
  table.dataTable thead>tr>td.sorting_asc,
  table.dataTable thead>tr>td.sorting_desc,
  table.dataTable thead>tr>td.sorting_asc_disabled,
  table.dataTable thead>tr>td.sorting_desc_disabled {
    padding-right: 26px !important;
  }
}

@media only screen and (max-width : 1199px) {
  .mgt-upload {
    margin-top: 32px !important;
  }

  .mgt-head-mob {
    margin-top: 1rem !important;
  }
}

@media only screen and (max-width : 1060px) {

  .btn-expFile,
  .btn-blue02 {
    padding: 0 3px;
  }

  .ic-add {
    width: 10px;
  }

  table.dataTable thead>tr>th.sorting,
  table.dataTable thead>tr>th.sorting_asc,
  table.dataTable thead>tr>th.sorting_desc,
  table.dataTable thead>tr>th.sorting_asc_disabled,
  table.dataTable thead>tr>th.sorting_desc_disabled,
  table.dataTable thead>tr>td.sorting,
  table.dataTable thead>tr>td.sorting_asc,
  table.dataTable thead>tr>td.sorting_desc,
  table.dataTable thead>tr>td.sorting_asc_disabled,
  table.dataTable thead>tr>td.sorting_desc_disabled {
    padding-right: 25px !important;
  }
}

@media only screen and (max-width : 992px) {

  .ic-files,
  .ic-add {
    margin-right: 0;
  }

  .dataTables_wrapper {
    overflow-x: scroll;
  }

  .rotate0 {
    transform: rotate(0deg) !important;
  }

  .rotate-90 {
    transform: rotate(-90deg) !important;
  }

  .showMenu {
    display: block !important;
  }

  .hideMenu {
    display: none !important;
  }

  .ic-search {
    margin-right: 0px;
  }

  .mgt-mob {
    margin-top: 5px !important;
  }
}

@media only screen and (max-width : 600px) {
  .im-tiltle {
    width: 35px;
  }

  .btn-expFile,
  .btn-blue02 {
    height: 30px;
  }

  .ic-files {
    width: 15px;
  }

  .btn-green01 {
    height: 45px;
    padding: 10px;
  }
}

@media only screen and (max-width : 575px) {
  .btn-blue05 {
    height: 30px;
  }

  .ic-search {
    margin-right: 5px;
  }

  .im-logo {
    width: 100%;
  }
}

@media only screen and (max-width : 375px) {
  .card-white {
    padding: 10px 5px;
  }

  .btn-search,
  .btn-reset {
    width: 100px;
  }
}

/* btn */

button:disabled,
button[disabled] {
  /* pointer-events: none !important; */
  opacity: 0.65 !important;
}

.col-1-5 {
  width: 14% !important;
  padding: 3px;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.pagination {
  justify-content: end !important;
}

.pagination li {
  /* Your pagination list item styles */
}

.pagination li.active button.pageNum {
  /* Your active page button styles */
  background-color: #007BFF;
  color: #fff;
  border: 1px solid #007BFF;
  /* Add more styles as needed */
}

.pagination li button.prev {
  border: 0px solid #D1D3D4;
  border-radius: 5px 0 0 5px;
  padding: 7px 10px;
  background-color: transparent;
}

.pagination li button.next {
  border: 0px solid #D1D3D4;
  border-radius: 0 5px 5px 0;
  padding: 7px 10px;
  background-color: transparent;
}

.pagination li button.pageNum {
  border: 1px solid #D1D3D4;
  border-radius: 0;
  padding: 5px 10px;
  background-color: transparent;
}

.background-primary {
  background: var(--bannerBgColor) !important;
  /* #00A79D !important */
}

/* .main-sidebar,
.main-sidebar::before {
  width: 300px;
}

.sidebar-mini .main-sidebar .nav-link,
.sidebar-mini-md .main-sidebar .nav-link,
.sidebar-mini-xs .main-sidebar .nav-link {
  width: calc(300px - 0rem * 2);
} */

/* .sidebar-collapse.sidebar-mini .main-sidebar .nav-sidebar .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar .nav-sidebar .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar .nav-sidebar .nav-link {
  width: 4.6rem !important;
  padding-left: 24.5px;
} */

/* .sidebar {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
} */

/* .nav-pills {
  --bs-nav-pills-border-radius: 0rem !important;
}

.nav-pills .nav-link {
  border-left: 7px solid #ffffff;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  border-left: 7px solid #ED1C24;
} */

.col-20 {
  width: 20% !important;
}

.wh-sp {
  white-space: nowrap !important;
}

.text-shadow-nm {
  text-shadow: 1px 1px #696767;
}

.mbs-4 {
  margin-bottom: 4px;
}

.mts-5 {
  margin-top: 5px;
}

.mg-card-user {
  margin: 5px 0 15px 0;
}

.mg-3-style {
  margin: 3px;
}

.ml-4-style {
  margin-left: 4px !important;
}

.wh-sp {
  white-space: nowrap !important;
}

.inp-textarea {
  display: block;
  /* width: 100%;
  height: 40px; */
  padding: 0.375rem 0.75rem !important;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  resize: none !important;
}

.inp-textarea:disabled {
  background-color: #E8E8E8;
  border: 1px solid #D1D3D4;
  resize: none !important;
}

/* --------- */
.accordion-button:not(.collapsed)::after {
  /* background-image: var(--bs-accordion-btn-active-icon); */
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)) !important
  ; */
  /* background-image: url("../public/assets/icons/ic-calendar3.png") !important; */
  /* content: "\f007" !important; */
  /* transform: var(--bs-accordion-btn-icon-transform); */
  font-size: 50px !important;
}

/* --------- */
.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
  border-radius: 1px !important;
}

.accordion-button:not(.collapsed) {
  color: black !important;
  background-color: white !important;
  /* border-top-left-radius: 10px !important;  
  border-top-right-radius: 10px !important; */
  /* 
 
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color); */
  /* border-top-left-radius: 10px !important;  
  border-top-right-radius: 10px !important; */
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 2px;
  border: 1px solid #D1D3D4 !important;
  /* border-radius: 10px ; */
}

.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
  /* border-radius: 10px; */
}

.card-term {
  width: 970px !important;
  background-color: #E8E8E8 !important;
  border: 1px solid #D1D3D4 !important;
  box-shadow: 0 0 0px rgba(0, 0, 0, .125), 0 0px 0px rgba(0, 0, 0, .2) !important;

  /* display: none !important; */
}

.view-edit-term {
  margin: -33px 0 0 48px !important;
}

.text-dl {
  text-decoration-line: none !important;
}

.ml-5-style {
  margin-left: -5px;
}

.mt-45-style {
  margin-top: 45px !important;
}

.eye-password-style {
  margin-top: -38px !important;
  margin-right: 9px !important;
}

/* ---new  */
.rotate-90-icon {
  transform: rotate(-90deg) !important;
}

.rotate-180-icon {
  transform: rotate(-180deg) !important;
}

/* .input-datasearch{
  position: relative;
  width: 100%;
  height: 64px;
  padding: 5px 10px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #D1D3D4;
} */

.inp-search {
  display: block;
  width: 100%;
  height: 30px;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.inp-search:disabled {
  background-color: #E8E8E8;
  border: 1px solid #D1D3D4;
}

.form-floating>label {
  font-size: medium !important;
  font-weight: normal !important;
}

.container-fluid.pdb-page {
  background: white !important;
}

.circle {
  width: 100px;
  /* Adjust width and height as needed */
  height: 100px;
  background-color: #F5F5F7;
  /* Change color as needed */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-text {
  font-size: xxx-large;
  /* Adjust font size as needed */
  color: #808285;
  /* Change text color as needed */
  margin: 0;
  /* Remove default margin */
}

.div1-detail {
  grid-area: 1 / 1 / 2 / 2;
}

.div2-detail {
  grid-area: 1 / 2 / 2 / 3;
  margin-right: 20px !important;
  width: 100%;
}

.div3-detail {
  grid-area: 1 / 3 / 2 / 4;
  margin-left: 50px !important;
}

.div3-detail-banner {
  grid-area: 1 / 3 / 2 / 4;
  margin-left: 22px !important;
}

.div3-detail-customer {
  grid-area: 1 / 3 / 2 / 4;
  margin-left: 22px !important;
}

.div3-detail-contact {
  grid-area: 1 / 3 / 2 / 4;
  margin-left: 0px !important;
}

.div4-detail {
  grid-area: 1 / 4 / 2 / 5;
}

.radio-group {
  display: flex;
  flex-wrap: nowrap;
  /* Prevent wrapping */
}

@media only screen and (max-width : 1400px) {
  .wd-logo {
    width: 80px;
  }

  .parent-detail {
    /* display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px; */
    display: grid;
    /* grid-template-columns: 110px 180px 120px 180px; */
    grid-template-columns: 100px 200px 110px 190px;
    grid-template-rows: 1fr;
    grid-column-gap: 45px;

  }

  .parent-detail-cus {
    display: grid;
    grid-template-columns: 85px 200px 125px 200px;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
  }

  .parent-detail-banner {
    /* display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: -10rem; */
    display: grid;
    grid-template-columns: 100px 280px 100px 280px;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
  }

  .parent-detail-calendar {
    display: grid;
    grid-template-columns: 130px 230px 150px 230px;
    grid-template-rows: 1fr;
    grid-column-gap: 0rem;
  }

  .parent-detail-contact {
    display: grid;
    grid-template-columns: 150px 200px 190px 200px;

    /* grid-template-columns: 100px 280px 100px 280px; */
    grid-template-rows: 1fr;
    grid-column-gap: 5px;

  }

  .div3-detail-contact {
    grid-area: 1 / 3 / 2 / 4;
    margin-left: 40px !important;
  }

  .div2-detail-calendar {
    grid-area: 1 / 2 / 2 / 3;
    /* margin-left: -60px;
  margin-right: 80px !important; */
  }

  .form-check-sm {
    font-size: 13px !important;
  }

  /* .div2-detail { 
  grid-area: 1 / 2 / 2 / 3;
  margin-right: 30px !important;
 } */
}

@media only screen and (min-width : 1400px) {
  .wd-logo {
    width: 50%;
  }

  .parent-detail-contact {
    display: grid;
    /* grid-template-columns: repeat(4, 1fr); */
    grid-template-columns: 200px 280px 200px 280px;
    /* grid-template-columns: 100px 280px 100px 280px; */
    grid-template-rows: 1fr;
    grid-column-gap: 0px;

  }

  .parent-detail {
    /* display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: -10rem; */
    display: grid;
    grid-template-columns: 100px 280px 100px 280px;
    grid-template-rows: 1fr;
    grid-column-gap: 67px;
  }

  .parent-detail-banner {
    /* display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: -10rem; */
    display: grid;
    grid-template-columns: 100px 280px 100px 430px;
    grid-template-rows: 1fr;
    grid-column-gap: 33px;
  }

  .parent-detail-cus {
    display: grid;
    grid-template-columns: 150px 300px 150px 300px;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
  }

  .parent-detail-calendar {
    display: grid;
    grid-template-columns: 180px 300px 180px 300px;
    grid-template-rows: 1fr;
    grid-column-gap: 0rem;

  }

  .div2-detail-calendar {
    grid-area: 1 / 2 / 2 / 3;
    margin-left: -60px;
    margin-right: 80px !important;
  }

  .div3-detail-contact {
    grid-area: 1 / 3 / 2 / 4;
    margin-left: 20px !important;
  }
}

.radio-group .form-check {
  margin-right: 10px;
  /* Adjust the spacing between radio buttons */
}

.inp-checkbox {
  width: 18px;
  height: 18px;
}

.mr-29 {
  margin-left: -29px !important;
}

textarea {
  resize: none !important;
  background-color: white;
  border: 1px solid #D1D3D4;
  border-radius: 5px;
  width: 100% !important;
}

.wd-14 {
  width: 14% !important;
}

.parent-password {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.div1-password {
  grid-area: 1 / 1 / 2 / 2;
}

.div2-password {
  grid-area: 1 / 2 / 2 / 3;
}

.box-password {
  display: block;
  width: 22rem;
  padding: 0px 0.75rem;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #A7A9AC;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  height: 40px;
}

.inp-radi-password {
  font-size: 13px;
  font-weight: 400 !important;
}

.ml-password {
  margin-left: 1.4rem !important;
}

.btn-password {
  display: block !important;
  width: inherit;
  height: 35px !important;
  padding: 0.375rem 0.75rem !important;
  line-height: 1.5 !important;
  color: #fff !important;
  background-color: #ED1C24 !important;
  background-clip: padding-box !important;
  border: 1px solid #ED1C24 !important;
  border-radius: 25px !important;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
  cursor: pointer !important;
  margin-bottom: 20px !important;
  text-align: center !important;

}

.ml-btn-password {
  margin-left: 4rem !important;
}

/* a {
  color: black !important;
  text-decoration: underline;
} */
a:hover,
a:focus {

  /* color: white !important; */
  text-decoration: none;
}

/* a.nav-link {
  color: #000000b3 !important;
} */

.status-indicator-container {
  display: flex;
  /* Use flexbox to align items */
  align-items: center;
  /* Center items vertically */
}

.dot-finish {
  width: 13px;
  /* Set the width of the circle */
  height: 13px;
  /* Set the height of the circle */
  background-color: #00A651;
  /* Green background color */
  border-radius: 50%;
  /* Makes the div a circle */
  margin-right: 2px;
  /* Space between the dot and the text */
  position: relative;
  /* Allows pseudo-elements positioning */
}

.dot-upcoming {
  width: 13px;
  /* Set the width of the circle */
  height: 13px;
  /* Set the height of the circle */
  background-color: #DDDEDF;
  /* Green background color */
  border-radius: 50%;
  /* Makes the div a circle */
  margin-right: 2px;
  /* Space between the dot and the text */
  position: relative;
  /* Allows pseudo-elements positioning */
}

.dot-inprogress {
  width: 13px;
  /* Set the width of the circle */
  height: 13px;
  /* Set the height of the circle */
  background-color: #F7BC0B;
  /* Green background color */
  border-radius: 50%;
  /* Makes the div a circle */
  margin-right: 2px;
  /* Space between the dot and the text */
  position: relative;
  /* Allows pseudo-elements positioning */
}

.dot-cancel {
  width: 13px;
  /* Set the width of the circle */
  height: 13px;
  /* Set the height of the circle */
  background-color: #EC1B30;
  /* Green background color */
  border-radius: 50%;
  /* Makes the div a circle */
  margin-right: 2px;
  /* Space between the dot and the text */
  position: relative;
  /* Allows pseudo-elements positioning */
}
.dot-working {
  width: 13px;
  /* Set the width of the circle */
  height: 13px;
  /* Set the height of the circle */
  background-color: #C8E6B2;
  /* Green background color */
  border-radius: 50%;
  /* Makes the div a circle */
  margin-right: 2px;
  /* Space between the dot and the text */
  position: relative;
  /* Allows pseudo-elements positioning */
}
/* Add a flashing effect to indicate "up-coming" status */

.status-text {
  font-size: 16px;
  /* Set the text size */
  color: #333;
  /* Set the text color */
}


.customize_ck {
  margin-bottom: 4rem;
}

.customize_ck .ck-editor__main {
  max-height: 400px !important;
  overflow-y: scroll;
}
.customize_ck .ck-editor__main .ck-content{
  min-height: 200px !important;
  width: 100% !important;
}

.wh-sp {
  white-space: nowrap !important;
}

.text-success {
  color: #87BD66 !important;
}

.text-danger {
  color: rgb(217, 83, 80) !important;
}

.text-header {
  color: #1254E0 !important;
}

.table-striped {
  background-color: white !important;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  background-color: #f2f2f2 !important;
}

.customize-record-table .pd-6-table {
  height: 44px !important;
}

.inp-login {
  display: block;
  width: 100%;
  height: 40px;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.inp-login:disabled {
  background-color: #E8E8E8;
  border: 1px solid #D1D3D4;
}

.circular-progressbar .CircularProgressbar-text {
  font-size: 16px; /* Adjust as needed */
  text-anchor: middle;
  dominant-baseline: central;
  fill: #000; /* Text color */
}