.learner-main-header {
  position: fixed;
  top: 0;
  left: 0; /* width of the sidebar */
  right: 0;
  background-color: #ffffff !important;
  border-bottom: 1px solid #dee2e6 !important;
  z-index: 1030;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: left 0.3s; /* Smooth transition for left margin */
}

.main-header.collapsed {
  left: 60px; /* Adjust according to the collapsed sidebar width */
}

.learner-header-title {
  margin-top: 20px;
  background-color: #1254E0;
  color: #FFFFFF;
  text-align: left;
  padding: 10px 40px;
  font-size: 18px;
  font-weight: bold;
}

.learner-header-btn {
  background-color: #1254E0;
  border: 1px solid #1254E0;
  border-radius: 5px;
  width: auto;
  cursor: pointer;
  color: white;
  margin-left: 10px;
}

.navbar {
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
}

.navbar .nav-item .nav-link {
  color: #343a40;
}

.navbar .nav-item .nav-link:hover {
  color: #007bff;
}

.navbar .nav-item .btn-link {
  color: #343a40;
}

.navbar .nav-item .btn-link:hover {
  color: #007bff;
}

.user-info-container {
  display: flex;
  align-items: center;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-right: 10px;
}

.user-info .nav-link {
  padding: 0;
  margin: 0;
  line-height: 1.2;
}

.navbar-nav .user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
}

.brand-image {
  max-height: 60px;
  transition: max-width 0.3s, max-height 0.3s; 
}