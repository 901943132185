.main-footer {
  margin-top: 1rem;
  background-color: #f8f9fa !important; 
  /* border-top: 1px solid #dee2e6; */
  padding: 1rem;
  text-align: center;
  /* color: #343a40; */
  /*width: calc(100% - 230px);  width of the sidebar */
  /* width:  max-content; */
  margin-left: 230px;
  /* position: fixed; */
  bottom: 0;
}

.main-footer a {
  color: #007bff;
}

.main-footer a:hover {
  color: #0056b3;
}

.float-right {
  float: right;
}

.footer-content {
  display: flex;
  justify-content: middle;
  align-items: center;
}
