/* .class-summary-card {
  background-color: #d0e6ff; 
  border-radius: 12px;
} */

.class-info-section {
  background-color: #d0e6ff; 
  border-radius: 12px;
}

.class-info-section h5 {
  font-weight: bold;
  margin-bottom: 4px;
}

.class-info-section h4 {
  margin-bottom: 8px;
}

.vertical-divider {
  width: 1px;
  height: 20px;
  background-color: #000;
}

.stat-box {
  border-radius: 8px;
  width: max-content; /* Adjust the width as needed */
}

.bg-purple {
  background-color: #e0d8ff; /* Light purple background */
}

.bg-green {
  background-color: #d0ffd8; /* Light green background */
}


.learner-side-panel-header {
    padding: 20px;
    /* background-color: #f8f9fa; */
    /* border-bottom: 1px solid #dee2e6; */
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  
  .learner-side-panel-header h4 {
    margin: 0;
    font-weight: bold;
  }
  
  .learner-side-panel-header p {
    margin: 0;
    color: #6c757d;
  }
  
  .learner-close-btn {
    font-size: 1.5rem;
    color: #333;
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
  }
  
  .learner-close-btn:hover {
    color: #000;
  }
  
  .side-panel {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
