.learner-side-panel-content {
    padding: 20px;
  }
  
  .learner-header {
    margin-bottom: 20px;
  }
  
  .learner-total-progress {
    font-size: 2rem;
  }
  
  .learner-progress-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .learner-progress-card {
    background-color: white;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
    width: calc(33.333% - 20px); /* Adjust width as needed for responsiveness */
    margin-bottom: 20px;
  }
  
  .learner-progress-card-title {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .learner-subsection-progress {
    margin-bottom: 10px;
  }
  
  .learner-subsection-progress p {
    margin-bottom: 5px;
  }

  .custom-progress-bar .progress-bar {
    color: #fff; /* White text inside the bar for better contrast */
    font-weight: bold;
    border-radius: 10px; /* Optional: Make the progress bar rounded */
  }
  .learner-subsection-progress .progress {
    background-color: #EFF0F4; /* Background color of the entire progress bar */
    height: 20px; /* Adjust the height as needed */
    border-radius: 10px; /* Optional: Make the progress bar rounded */
  }
  
  .learner-subsection-progress .progress-bar {
    color: #fff; /* White text inside the bar for better contrast */
    font-weight: bold;
    border-radius: 10px; /* Optional: Make the progress bar rounded */
  }