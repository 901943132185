.main-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 230px;
  background-color: #f8f9fa;
  color: #000;
  z-index: 1000;
  overflow-y: auto;
  transition: width 0.3s;
}

.main-sidebar.collapsed {
  width: 60px; /* Adjust width as needed for collapsed state */
}

.main-sidebar .brand-link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  background-color: #fff;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 5px;
  transition: width 0.3s; /* Smooth transition */
}

.main-sidebar .brand-image {
  max-height: 50px;
  transition: max-width 0.3s, max-height 0.3s; 
}

.main-sidebar.collapsed .brand-link {
  width: 60px; 
}

.main-sidebar.collapsed .brand-image {
  max-width: 40px;
}

.main-sidebar.collapsed .sidebar-arrow {
  display: none;
}

.main-sidebar .brand-user {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-size: 16px;
  color: #6c757d;
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
}

.sidebar {
  padding-top: 10px;
}

.sidebar-title {
  background-color: #007bff;
  color: white;
  text-align: center;
  padding: 10px 0;
  font-size: 18px;
  font-weight: bold;
}

.sidebar .nav-item {
  margin-bottom: 10px;
  margin-left: 5px;
}

.main-sidebar.collapsed .nav-item {
  margin-bottom: 10px;
  margin-left: 0px;
}

.sidebar .nav-link {
  display: flex;
  align-items: center;
  color: #495057;
  padding: 5px 15px;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.sidebar .nav-link:hover {
  background-color: #DAE6FE;
  color: #1254E0;
}

.sidebar .nav-icon {
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.sidebar .nav-item.active .nav-link {
  background-color: #DAE6FE;
  color: #1254E0;
}

.menu-open > .nav-treeview {
  display: block; /* Ensures submenu is visible when open */
}

.rotate-90 {
  transform: rotate(-90deg);
  transition: transform 0.3s ease;
}

.sidebar .nav-item.active .nav-link .nav-icon {
  color: #fff;
}

.sidebar .nav-text {
  display: inline;
}

.main-sidebar.collapsed .nav-text {
  display: none;
}

.logout-nav {
  margin-top: auto;
}

.logout-nav .nav-link {
  color: #495057;
}

.logout-nav .nav-link:hover {
  color: #fff;
  background-color: #dc3545;
}

.contact-section {
  padding: 15px;
  border-top: 1px solid #dee2e6;
}

.contact-section.collapsed {
  display: none;
}