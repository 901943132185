/* SidePanel.css */

/* Base styles for the side panel */
.side-panel {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 400px;
    background-color: #ffffff;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    transform: translateX(100%);
    transition: transform 0.4s ease-in-out;  /* Adjusted duration and timing function for smoother effect */
    z-index: 1050;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    pointer-events: none; /* Prevents clicks during transition */
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .side-panel {
      width: 100%;
    }
  }
  
  /* States */
  .side-panel.open {
    transform: translateX(0);
    pointer-events: all; /* Re-enable pointer events when fully open */
  }
  
  /* Header styling */
  .side-panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    /* background-color: #f1f1f1; */
    /* border-bottom: 1px solid #dddddd; */
  }

  .side-panel-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: #f1f1f1;
    /* border-bottom: 1px solid #dddddd; */
  }
  
  
  .side-panel-header h3 {
    font-size: 24px; /* 1.5rem assuming 16px base font size */
    font-weight: bold;
    margin: 0;
    color: #333333;
  }
  
  /* Close button styling */
  .close-btn {
    font-size: 24px; /* 1.5rem for visibility */
    color: #000000;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .close-btn:hover, .close-btn:focus {
    color: #ff0000;
    outline: none; /* Removes focus outline for aesthetics, ensure accessibility is handled elsewhere */
  }
  
  /* Content Area */
  .side-panel-content {
    padding: 16px;
  }
  
  .side-panel-content h4,
  .side-panel-content h5,
  .side-panel-content p {
    margin-top: 8px;
    margin-bottom: 16px;
  }
  
  .side-panel-content h4 {
    font-size: 20px; /* 1.25rem */
    color: #007bff;
  }
  
  .side-panel-content h5 {
    font-size: 16px; /* 1rem */
    color: #666666;
  }
  
  .side-panel-content img {
    width: 100%;
    height: auto;
    margin-bottom: 16px;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .side-panel-content {
    padding: 16px;
    font-size: 1rem;
    color: #333;
  }
  
  .info-section {
    border: 1px solid #ccc; /* Adds a light grey border around each section */
    padding: 8px;
    margin-bottom: 10px; /* Space between sections */
    background-color: #ffffff; /* Slight background color for better contrast */
    border-radius: 20px;
  }
  
  .label {
    font-weight: bold; /* Makes label text bold */
    display: block; /* Makes the label take the full width of the line */
    margin-bottom: 5px; /* Space between label and content */
  }
  
  .side-panel-content p {
    margin: 0; /* Removes default margin */
    line-height: 1.5; /* Improves readability */
  }
  
  .side-panel-content a {
    color: #007bff; /* Uses a bright blue color for links */
    text-decoration: none; /* No underline by default */
  }
  
  .side-panel-content a:hover {
    text-decoration: underline; /* Underline on hover for better interaction feedback */
  }
  
  /* Specific details styles */
  .section-title,
  .certificate-id,
  .certificate-description {
    color: #444444;
    font-weight: bold;
  }
  
  .section-title {
    font-size: 18px; /* 1.1rem */
    margin-top: 32px; /* 2rem */
  }
  
  .certificate-id {
    font-size: 20px; /* 1.25rem */
  }
  
  .certificate-description {
    background-color: #f9f9f9;
    padding: 16px;
    border-radius: 4px;
    border-left: 5px solid #007bff;
    font-size: 16px; /* 1rem */
    color: #666666;
  }
  