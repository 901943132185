.content {
  padding: 20px;
  padding-bottom: 30px;
}

.container-fluid {
  /* max-width: 1200px; */
  margin: 0 auto;
}


.top-scorers {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  overflow: auto;
}

.scorer {
  background-color: #f4f6f9;
  border: 1px solid #dee2e6;
  padding: 15px;
  text-align: center;
  flex: 1;
  margin: 0 10px;
  border-radius: 10px;
}

.scorer h3 {
  font-size: 2em;
  margin-bottom: 10px;
}

.scorer p {
  margin: 5px 0;
}

.student-scores {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.student-scores th, .student-scores td {
  border: 1px solid #dee2e6;
  padding: 10px;
  text-align: center;
}

.student-scores th {
  background-color: #f4f6f9;
}

/* .charts {
  justify-content: space-between;
}

.chart {
  background-color: #f4f6f9;
  border: 1px solid #dee2e6;
  padding: 15px;
  text-align: center;
  margin: 0 10px 10px;
  border-radius: 10px;
}

.chart-placeholder {
  height: 200px;
  background-color: #e9ecef;
  border-radius: 10px;
} */

/* ****************** */
.charts {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Adds space between the charts */
}



.std-card {
  border: none;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
}

.std-card-body {
  padding: 1.5rem;
}

h5.std-card-title {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
}

p {
  margin-bottom: 0.5rem;
}

.text-primary {
  color: #007bff;
}
